/* Styles: Episode */

:root {
  --primary: #6464E6;
  --primary50: #eae9fc;
}

.episode-group {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  /* margin-bottom: 0.5rem; */
}
.episode {
  width: 100%;

  display: flex;
  align-items: center;

  border-top: 1px solid rgba(33, 33, 33, 0.08);

  /* background-color: rgba(33,33,33,0.08); */
  /* border-radius: 0.25rem; */
  /* margin-right: 0.5rem; */
  /* margin-bottom: 0.5rem; */
  padding: 0.5rem;

  min-height: 50px;
}
.episode:last-child {
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
}
.episode:hover {
  background-color: var(--primary50);
  /* color: var(--primary) !important; */
  cursor: pointer;
}
  .episode.active {
    background-color: var(--primary50) !important;
  }
  .episode-content {
    display: flex;
    flex-direction: column;

    font-size: 0.75rem;

    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
    overflow: hidden;
  }
    .episode-content-date {
      /* white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; */
    }
    .episode-content-info {
      font-weight: 700;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      /* margin-left: 0.5rem; */
    }
  .episode-actions {
    display: flex;
    flex-wrap: nowrap;

    margin: -0.25rem;
  }

.episode-add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* border-radius: 0.25rem; */
  color: var(--primary);
  padding: 0.5rem;

  margin-bottom: 0.5rem;
}
.episode-add:hover {
  background-color: var(--primary50);
}

/* Move to icon */
.icon-wrapper {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

