/* Cards */

.card-grid-item {
  display: flex;
  flex-direction: column;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* display: flex; */
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  /* border-radius: 4px; */
  min-height: 440px;
  /* flex-grow: 1; */
}
.card-wrapper .lazyload-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  border: 1px solid rgba(0,0,0,0.12);
  background-color: white;
  border-radius: 0.25rem;
  padding: 12px 16px;
  /* overflow: hidden; */
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-grow: 1; */
  /* position: relative; */
  /* min-height: 360px; */
}
.card:hover {
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
  .card.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .card.highlighted {
    border-color: var(--primary);
  }
  .card.highlighted:hover {
    box-shadow: 0px 2px 8px rgba(76, 76, 255, 0.32);
  }
.card-header {
  display: flex;
  align-items: center;
  position: relative;
  /* padding: 8px 8px 8px 16px; */
  z-index: 1;
}
  .card-header-left {
    position: absolute;
    left: 0; top: 0;
    margin-left: -11px;
    margin-top: 8px;
    /* flex-shrink: 0; */
    /* padding-left: var(--spacing); */
    padding-right: var(--spacing);
  }
    .card-color {
      display: block;
      width: 20px;
      height: 20px;
      /* background-color: white; */
      border: 1px solid #fafafa;
      border-radius: 50%;
      /* cursor: pointer; */
    }
    .card-color + .card-color {
      margin-top: 4px;
    }
    .card-color.red { background-color: var(--red); border-color: var(--red); }
    .card-color.purple { background-color: var(--purple); border-color: var(--purple); }
    .card-color.deepPurple { background-color: var(--deepPurple); border-color: var(--deepPurple); }
    .card-color.indigo { background-color: var(--indigo); border-color: var(--indigo); }
    .card-color.blue { background-color: var(--blue); border-color: var(--blue); }
    .card-color.lightBlue { background-color: var(--lightBlue); border-color: var(--lightBlue); }
    .card-color.cyan { background-color: var(--cyan); border-color: var(--cyan); }
    .card-color.teal { background-color: var(--teal); border-color: var(--teal); }
    .card-color.green { background-color: var(--green); border-color: var(--green); }
    .card-color.amber { background-color: var(--amber); border-color: var(--amber); }
    .card-color.deepOrange { background-color: var(--deepOrange); border-color: var(--deepOrange); }
    .card-color.brown { background-color: var(--brown); border-color: var(--brown); }
    .card-color.blueGrey { background-color: var(--blueGrey); border-color: var(--blueGrey); }
  .card-header-body {
    flex-grow: 1;
    overflow: hidden;
  }
    .card-header-body__top {
      display: flex;
      align-items: center;
    }
.card-body {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  height: 200px;
}
  .card-body.deleted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    position: relative;
    overflow: hidden;
    min-height: 160px;
  }
  .card-body:hover .card-direction {
    display: block;
  }
  .card-direction {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: calc(var(--spacing)/2);
    padding-right: calc(var(--spacing)/2);
    z-index: 3;
  }
  .card-direction.left { left: 0; }
  .card-direction.right { right: 0; }
    .card-direction-button {
      background-color: var(--primary) !important;
      color: white !important;
    }
    .card-direction-button:hover {
      background-color: var(--blueGrey) !important;
    }
  .card-body-image {
    position: absolute;
    top: 0; left: 0;
    max-width: 100%;
  }
.card-footer {
  display: flex;
  flex-direction: row;
  /* padding: var(--spacing); */
}
  .card-footer-compare,
  .card-footer-staple {
    display: flex;
    align-items: center;
    text-align: left;
  }
  .card-footer-duplicate,
  .card-footer-workspace {
    text-align: right;
  }


.card-header-color {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  z-index: 1;
}
.card-header-color.red { background-color: #f44336; }
.card-header-color.purple { background-color: #9c27b0; }
.card-header-color.deepPurple { background-color: #673ab7; }
.card-header-color.indigo { background-color: #3f51b5; }
.card-header-color.blue { background-color: #2196f3; }
.card-header-color.lightBlue { background-color: #03a9f4; }
.card-header-color.cyan { background-color: #00bcd4; }
.card-header-color.teal { background-color: #009688; }
.card-header-color.green { background-color: #4caf50; }
.card-header-color.amber { background-color: #ffc107; }
.card-header-color.deepOrange { background-color: #ff5722; }
.card-header-color.brown { background-color: #795548; }
.card-header-color.blueGrey { background-color: #607d8b; }



/* Card image preview */
.image-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 45%;
  top: 0; bottom: 0;
  z-index: 1202;
  background-color: rgba(255,255,255,.95);
  border: 1px solid var(--grey);
}
.image-preview-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
.image-preview-header {
  display: flex;
  border-bottom: 1px solid var(--grey);
  padding: calc(var(--spacing)*2);
}
  .image-preview-header-left {
    flex-grow: 1;
  }
  .image-preview-header-right {
    padding-left: calc(var(--spacing)*2);
  }
.image-preview-body {
  flex-grow: 1;
  position: relative;
}
  .image-preview-body-image {
    display: block;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
  }
