/* Fonts */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

:root {
  /* --primary: #2a628b; */
  --primary: #6464E6;
  --red: #f44336;
  --pink: #e91e63;
  --purple: #9c27b0;
  --deepPurple: #673ab7;
  --indigo: #3f51b5;
  --blue: #2196f3;
  --lightBlue: #03a9f4;
  --cyan: #00bcd4;
  --teal: #009688;
  --green: #4caf50;
  --lightGreen: #8bc34a;
  --lime: #cddc39;
  --yellow: #ffeb3b;
  --amber: #ffc107;
  --orange: #ff9800;
  --deepOrange: #ff5722;
  --brown: #795548;
  --grey: #9e9e9e;
  --blueGrey: #607d8b;

  --spacing: 8px;
  --toolbarHeight: 64px;

  --borderColor: #e0e0e0;
}

body {
  padding: 0;
  margin: 0;
}

iframe {
  width: 100% !important;
}

@import './assets/styles/loading.css';
@import './assets/styles/buttons.css';
@import './assets/styles/cards.css';

.canvasjs-chart-tooltip {
  pointer-events: auto !important;
}

/* Dialog */
.dialog-form {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.hightlight {
  background-color: var(--yellow);
}

#webpack-dev-server-client-overlay {
  display: none;
}