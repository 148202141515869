/* Components: Page card */

/* Come from lazyLoad */
.card-page-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-page-placeholder {
  /* min-height: 400px; */
  background-color: white;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 4px;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.card-page {
  
}
.card-page.deleted {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}
  .card-page-pageNum {
    position: absolute;
    top: 0; left: 0;

    min-width: 32px;

    background-color: white;
    border: 1px solid rgba(0,0,0,0.08);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 700;
    padding: 4px 8px;
    margin: 16px;
  }
  .card-page-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .card-page-image-body {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
  .card-page-image-body-search {
    width: 100%;
    height: 100%;
  }
.card-page-footer {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.card-page-preview {
  position: fixed;
  top: 0; right: 0; bottom: 0;
  width: 45%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.12);
  border-radius: 4px;
  margin: 8px;
  overflow: hidden;
  z-index: 9999;
}
.card-page-preview.left {
  right: initial;
  left: 0;
}