/* Components: Colors */

.initial { color: rgba(0,0,0,1); background-color: rgba(0,0,0,0.08) !important; }
.bg-initial { background-color: rgba(0,0,0,0.08) !important; }

.primary { color: var(--primary) !important; background-color: var(--primaryLight) !important; }
.bg-primary { background-color: var(--primary) !important; }

.red { color: var(--red) !important; background-color: var(--redLight) !important; }
.bg-red { background-color: var(--red) !important; }

.purple { color: var(--purple) !important; background-color: var(--purpleLight) !important; }
.bg-purple { background-color: var(--purple) !important; }

.deepPurple { color: var(--deepPurple) !important; background-color: var(--deepPurpleLight) !important; }
.bg-deepPurple { background-color: var(--deepPurple) !important; }

.indigo { color: var(--indigo) !important; background-color: var(--indigoLight) !important; }
.bg-indigo { background-color: var(--indigo) !important; }

.blue { color: var(--blue) !important; background-color: var(--blueLight) !important; }
.bg-blue { background-color: var(--blue) !important; }

.lightBlue { color: var(--lightBlue) !important; background-color: var(--lightBlueLight) !important; }
.bg-lightBlue { background-color: var(--lightBlue) !important; }

.cyan { color: var(--cyan) !important; background-color: var(--cyanLight) !important; }
.bg-cyan { background-color: var(--cyan) !important; }

.teal { color: var(--teal) !important; background-color: var(--tealLight) !important; }
.bg-teal { background-color: var(--teal) !important; }

.green { color: var(--green) !important; background-color: var(--greenLight) !important; }
.bg-green { background-color: var(--green) !important; }

.amber { color: var(--amber) !important; background-color: var(--amberLight) !important; }
.bg-amber { background-color: var(--amber) !important; }

.deepOrange { color: var(--deepOrange) !important; background-color: var(--deepOrangeLight) !important; }
.bg-deepOrange { background-color: var(--deepOrange) !important; }

.brown { color: var(--brown) !important; background-color: var(--brownLight) !important; }
.bg-brown { background-color: var(--brown) !important; }

.blueGrey { color: var(--blueGrey) !important; background-color: var(--blueGreyLight) !important; }
.bg-blueGrey { background-color: var(--blueGrey) !important; }


/* Color item */
.color-item {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}