@font-face {
  font-family: 'CaseChronologyIcons';
  src:  url('../fonts/CaseChronologyIcons/CaseChronologyIcons.eot?lxg2gd');
  src:  url('../fonts/CaseChronologyIcons/CaseChronologyIcons.eot?lxg2gd#iefix') format('embedded-opentype'),
    url('../fonts/CaseChronologyIcons/CaseChronologyIcons.ttf?lxg2gd') format('truetype'),
    url('../fonts/CaseChronologyIcons/CaseChronologyIcons.woff?lxg2gd') format('woff'),
    url('../fonts/CaseChronologyIcons/CaseChronologyIcons.svg?lxg2gd#CaseChronologyIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CaseChronologyIcons' !important;
  speak: never;
  /* font-size: 24px; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]::before, [class*=" icon-"]::before {
  display: inline-block;
}

.icon-shared:before { content: "\e924"; }
.icon-client:before { content: "\e925"; }
.icon-add:before { content: "\e900"; }
.icon-add_to_workspace:before { content: "\e901"; }
.icon-additional:before { content: "\e902"; }
.icon-arrow_drop_down:before { content: "\e903"; }
.icon-arrow_upward:before { content: "\e904"; }
.icon-batch:before { content: "\e905"; }
.icon-billing:before { content: "\e906"; }
.icon-case_number:before { content: "\e907"; }
.icon-chevron_left:before { content: "\e908"; }
.icon-chevron_right:before { content: "\e909"; }
.icon-close:before { content: "\e90a"; }
.icon-color:before { content: "\e90b"; }
.icon-columns_2:before { content: "\e90c"; }
.icon-columns_3:before { content: "\e90d"; }
.icon-columns_4:before { content: "\e90e"; }
.icon-columns_auto:before { content: "\e90f"; }
.icon-compare:before { content: "\e910"; }
.icon-delete:before { content: "\e911"; }
.icon-Divider:before { content: "\e912"; }
.icon-done:before { content: "\e913"; }
.icon-edit:before { content: "\e914"; }
.icon-event:before { content: "\e915"; }
.icon-headset_mic:before { content: "\e916"; }
.icon-highlight:before { content: "\e917"; }
.icon-load_data_to_daily_chart:before { content: "\e918"; }
.icon-more_vert:before { content: "\e919"; }
.icon-notes:before { content: "\e91a"; }
.icon-notifications_none:before { content: "\e91b"; }
.icon-preview:before { content: "\e91c"; }
.icon-rotate_right:before { content: "\e91d"; }
.icon-select_all:before { content: "\e91e"; }
.icon-staple_delete:before { content: "\e91f"; }
.icon-staple_edit:before { content: "\e920"; }
.icon-stapled:before { content: "\e921"; }
.icon-swap_horiz:before { content: "\e922"; }
.icon-team:before { content: "\e923"; }
