@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import './app/assets/styles/cc-quill-theme.css';

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@import './assets/styles/fonts-case-chronology-icon.css';
@import './app/assets/styles/colors.css';

:root {
  --primary: #6464E6;
  --primaryLight: #eae9fc;
  
  --red: #C9493A;
  --redLight: rgba(226, 82, 65, 0.15);
  
  --purple: #8F37AA;
  --purpleLight: rgba(143, 55, 170, 0.15);

  --deepPurple: #6042B0;
  --deepPurpleLight: rgba(96, 66, 176, 0.15);
  
  --indigo: #4054AF;
  --indigoLight: rgba(64, 84, 175, 0.15);
  
  --blue: #3E87D4;
  --blueLight: rgba(69, 150, 236, 0.15);
  
  --lightBlue: #4096D4;
  --lightBlueLight: rgba(72, 168, 237, 0.15);
  
  --cyan: #48A3B8;
  --cyanLight: rgba(82, 186, 209, 0.15);
  
  --teal: #419388;
  --tealLight: rgba(65, 147, 136, 0.15);
  
  --green: #57914D;
  --greenLight: rgba(103, 172, 91, 0.15);
  
  --amber: #C29936;
  --amberLight: rgba(246, 194, 68, 0.15);
  
  --deepOrange: #D45831;
  --deepOrangeLight: rgba(237, 98, 55, 0.15);
  
  --brown: #74564A;
  --brownLight: rgba(116, 86, 74, 0.15);
  
  --blueGrey: #657D8A;
  --blueGreyLight: rgba(101, 125, 138, 0.15);
}

@media print {
  [data-print="exclude"] {
    display: none !important;
  }
}

/* Message */
.message-item {
  list-style-type: none;
  padding-top: 4px;
  padding-bottom: 4px;
}
.message-item .username {
  display: block;
  font-size: 12px;
  font-weight: 700;
  padding-left: 2px;
  padding-right: 2px;
}
.message-item-content {
  display: inline-block;
  position: relative;
  max-width: 50%;
}
.message-item.own {
  text-align: right;
}
.message-item.own .text {
  background-color: white !important;
  color: black !important;
}
.message-item.own .date {
  right: initial;
  left: 0;
  color: black !important;
}
.message-item.cases .text {
  background-color: rgba(100,100,230,1);
  color: white;
}
.message-item.clients .text {
  background-color: #29b6f6;
  color: white;
}
.message-item-content .text {
  display: block;
  min-width: 200px;
  background-color: white;
  border-radius: 24px;
  color: black;
  padding: 8px 16px 16px;
}
.message-item-content .date {
  color: white;
  right: 0; bottom: 0;
  margin: 0px 16px 2px;
  position: absolute;
  font-size: 10px;
  white-space: nowrap;
}
/* ToDO */
.phoneContainer .special-label {
  color: rgba(0,0,0,0.6) !important;
  left: 10px !important;
  top: -9px !important;
  font-size: 12px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.phoneContainer .form-control {
  width: 100% !important;
}
.phoneContainer.error .special-label {
  color: #d32f2f !important;
}
.phoneContainer.error .form-control {
  border-color: #d32f2f !important;
}
.phoneContainer.error .form-control:focus {
  box-shadow: 0 0 0 1px #d32f2f !important;
}
.react-tel-input .flag-dropdown.open#top .country-list  {
  top: 0 !important;
  margin: -10px 0 0 !important;
  transform: translateY(-100%) !important;
}

.cp-pageNum {
  display: inline-block;
  width: 36px;
  height: 36px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.08);
  font-size: 16px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  z-index: 1;
}

.cp-pageNum-pdf {
  position: absolute;
  top: 0;
  right: 0;
  margin: 76px 16px 16px;
}

@import './@root/styles/episode.css';
@import './@root/styles/page-image.css';
@import './@root/styles/card-page.css';


.z-50 {
  z-index: 1301;
}
.z-40 {
  z-index: 1201;
}

.relative {
  position: relative;
}

/* Utility */
.divider {
  border-top: 1px solid rgba(33,33,33,0.08);
}

.system-alert {

}
.system-alert .MuiAlert-message {
  flex-grow: 1;
  font-weight: 700;
  padding-left: 40px;
  text-align: center;
}
.system-alert .MuiAlert-message a {
  text-decoration: underline;
}
.system-alert .MuiAlert-message a:hover {
  text-decoration: none;
}

.card-page-container {
  display: flex;
  padding-right: 16px;
  padding-bottom: 16px;
}

.card-page-item {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
}

/* ENDLESS ROTATE */
.rotate {
  animation: spinner 1.5s linear infinite;
}
@keyframes spinner {
  to { transform: rotate(360deg); }
}