/* Loading */

.circular-wrapper {
  text-align: center;
  padding-top: calc(var(--spacing)*2);
  padding-bottom: calc(var(--spacing)*2);
}

.linear-wrapper {
  position: relative;
}

.linear-progress {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  z-index: 10;
}

.linear-overlay {
  position: absolute;
  top: 0; left: 0; bottom: 0;
  width: 100%;
  background-color: rgba(255,255,255,0.5);
  margin-top: calc(var(--spacing)/2);
  z-index: 9;
}


/* Content loading */
.content-loading {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
