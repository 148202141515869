/* Card page */

.cp-separator {
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  color: rgba(0,0,0,0.75);
  font-size: 32px;
  font-weight: 700;
  text-transform: capitalize;
}

.cp-placeholder,
.cp {
  flex-grow: 1;

  background-color: white;
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 4px;
  padding: 12px 16px;

  overflow: hidden;
}

.cp {
  display: flex;
  flex-direction: column;

  position: relative;
}

.cp:hover {
  cursor: pointer;
  box-shadow: 0 2px 8px rgb(0,0,0,0.2);
}

.cp-colors {
  position: absolute;
  top: 0; left: 0;
  width: 8px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3px;

  margin-top: 16px;

  overflow: hidden;
}
  /* Color item */
  .cp-colors-item {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

.cp-head {

}
  .cp-text {
    color: rgba(0,0,0,0.87);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  .cp-text:hover {
    cursor: pointer;
  }
.cp-body {
  flex-grow: 1;
  display: flex;
  justify-content: center;

  height: 240px;
}
  .cp-body img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
.cp-foot {
  display: flex;
  gap: 4px;
}



.flex { display: flex; }
.flex-grow-1 { flex-grow: 1; }
.align-center { align-items: center; }
