.ql-container.ql-snow {
  border: 1px solid rgba(0,0,0,0.23) !important;
}

/* Preview */
.ql-editor.preview .ql-variable {
  background-color: transparent;
}
.ql-editor.preview .ql-variable::after {
  display: none;
}
.ql-editor.preview a.ql-link-variable::after {
  display: none;
}

.ql-editor .ql-cursor {
  background-color: black;
}

.ql-editor { font-size: 12pt; }

.ql-editor h1 { font-size: 16pt; }
.ql-editor h2 { font-size: 13pt; }
.ql-editor h3 { font-size: 12pt; }
.ql-editor h4 { font-size: 12pt; }
.ql-editor h5 { font-size: 12pt; }
.ql-editor h6 { font-size: 12pt; }

.ql-editor hr {
  position: relative;
  border-top: 1px dashed;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: visible;
}
.ql-editor hr::after {
  content: 'Page break';
  position: absolute;
  top: 50%; left: 50%;
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 2px 8px;
  transform: translate(-50%, -50%);
}

.ql-editor a {
  color: var(--primary);
  text-decoration: underline;
}
.ql-editor a:hover {
  cursor: pointer;
  text-decoration: none;
}

.ql-tab {
  display: inline;
  margin-left: 48px;
}

.ql-snow .ql-tooltip {
  left: 0 !important;
  z-index: 4;
}

.ql-hidden {
  display: none;
}

/* Fonts */
.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-font span[data-label="Calibri"]::before,
.ql-font-calibri { font-family: 'Calibri'; }

.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-font span[data-label="Courier New"]::before,
.ql-font-couriernew { font-family: 'Courier New'; }

.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-font span[data-label="Helvetica"]::before,
.ql-font-helvetica { font-family: 'Helvetica'; }

.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-font span[data-label="Times New Roman"]::before,
.ql-font-timesnewroman { font-family: "Times New Roman"; }

/* Default tag */
.ql-snow .ql-editor img {
  display: inline-block;
}

.cc-toolbar.ql-snow.ql-toolbar {
  border: 0;
  border-radius: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.cc-toolbar.ql-snow.ql-toolbar .ql-formats {
  margin-right: 0;

  display: flex;
}

.cc-toolbar.ql-snow.ql-toolbar button {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
}
.cc-toolbar.ql-snow.ql-toolbar button.ql-hidden {
  display: none;
}
/* .cc-toolbar.ql-snow.ql-toolbar button + button {
  margin-left: 4px;
} */
.cc-toolbar.ql-snow.ql-toolbar button:hover,
.cc-toolbar.ql-snow.ql-toolbar button.ql-active,
.cc-toolbar.ql-snow.ql-toolbar .ql-picker-item:hover,
.cc-toolbar.ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  background-color: var(--primaryLight);
  color: var(--primary);
}
.cc-toolbar.ql-snow.ql-toolbar button .ql-stroke,
.cc-toolbar.ql-snow.ql-toolbar .ql-picker-label .ql-stroke {
  stroke: #666;
}
.cc-toolbar.ql-snow.ql-toolbar button:hover .ql-stroke,
.cc-toolbar.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.cc-toolbar.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.cc-toolbar.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
  stroke: var(--primary);
}
.cc-toolbar.ql-snow.ql-toolbar button .ql-fill {
  fill: #666;
}
.cc-toolbar.ql-snow.ql-toolbar button:hover .ql-fill,
.cc-toolbar.ql-snow.ql-toolbar button.ql-active .ql-fill {
  fill: var(--primary);
}

.cc-toolbar.ql-snow.ql-toolbar button svg {
  float: none;

  width: 75%;
  height: 75%;
}

.cc-toolbar.ql-snow.ql-toolbar .ql-picker {
  height: 32px;
  width: 98px;
}
/* Align */
.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-align {
  width: 32px;
}
.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-align .ql-picker-label {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-align .ql-picker-label svg {
  width: 75%;
  height: 75%;
}

.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-align .ql-picker-options .ql-picker-item {
  padding: 6px;
  width: 100%;
  height: 30px;
}


.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-font {
  min-width: 160px;
}
.cc-toolbar.ql-snow.ql-toolbar .ql-picker-label {
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
}
.cc-toolbar.ql-snow.ql-toolbar .ql-picker-label::before {
  line-height: 30px;
}

.cc-toolbar.ql-snow.ql-toolbar .ql-picker-label:hover,
.cc-toolbar.ql-snow.ql-toolbar .ql-picker-label.ql-active {
  color: var(--primary);
}

.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  box-shadow: none;

  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 4px;

  padding-right: 0;
  padding-left: 0;

  max-height: 240px;

  overflow-y: auto;
  z-index: 4;
}

/* Align: Remove scroll bar on OS */
.cc-toolbar.ql-snow.ql-toolbar .ql-align.ql-picker.ql-expanded .ql-picker-options {
  overflow-y: hidden;
}

.cc-toolbar.ql-snow.ql-toolbar .ql-picker-options .ql-picker-item {
  padding: 8px;
}

.cc-toolbar.ql-snow.ql-toolbar .ql-picker.ql-variables {
  width: 108px;
}

.cc-toolbar.ql-snow.ql-toolbar .ql-picker .ql-picker-label[data-label]:not([data-label=''])::before,
.cc-toolbar.ql-snow.ql-toolbar .ql-picker .ql-picker-item[data-label]:not([data-label=''])::before {
  content: attr(data-label);
}

.cc-toolbar.ql-snow.ql-toolbar .ql-picker .ql-picker-label[data-label]:not([data-label=''])::before {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}


/* Custom formatting */
.ql-variable {
  font-style: normal;
  text-decoration: inherit;

  display: inline-block;
  position: relative;
  background-color: rgba(137, 196, 244, 0.25);
  border-radius: 4px;
  color: black;
  padding: 2px;
}

.ql-variable:hover::after {
  position: absolute;
  left: 50%;
  top: 100%;
  background-color: #333;
  border-radius: 4px;
  color: white;
  font-weight: 400;
  padding: 4px;
  margin-top: 4px;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 4;
}

/* Custom link tooltip */
.ql-link-variable {
  position: relative;
}

.ql-link-variable:hover::after {
  content: attr(data-title);
  position: absolute;
  left: 0;
  top: 100%;
  background-color: var(--primary);
  border-radius: 4px;
  color: white;
  font-weight: 400;
  padding: 4px;
  margin-top: 4px;
  white-space: nowrap;
  z-index: 4;
}

/* Fullscreen */
.quill-wrapper.fullscreen {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 24px;
  z-index: 4;
}

.quill-wrapper.fullscreen .quill-editor {
  flex-grow: 1;
}