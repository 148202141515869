/* Components: Page image */

/* Image Selector */

.image-selector {
  position: absolute;
  top: 0; right: 0;
  width: 100%; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
  .image-selector-actions {
    background-color: white;
    position: absolute;
    top: 0; right: 0;
    z-index: 3;
    margin: 0 48px;
  }
  .image-selector-overlay {
    position: absolute;
    width: 100%; height: 100%;
  }
  .image-selector-overlay {
    /* border: 1px solid #ededed; */
    z-index: 1;
  }

.marker-placeholder {
  position: absolute;
  border: 2px solid;
}

.marker-highlight {
  position: absolute;
  background-color: rgb(255,233,137,0.55);
  z-index: 1;
  pointer-events: all;
}
.marker-highlight:hover {
  z-index: 2;
}
