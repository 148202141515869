/* Buttons */

.btn-quickbooks {
  border: none;
  background-color: transparent;

  background-image: url('../images/C2QB_green_btn_lg_default.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  width: 240px;
  height: 42px;
}
.btn-quickbooks:hover {
  background-image: url('../images/C2QB_green_btn_lg_hover.png') !important;
  cursor: pointer;
}
